import React, { useState } from 'react'
import ReactDOM from "react-dom"

const FAQ = ({ FAQContent }) => {
  const [activeTab, setactiveTab] = useState([0])

  const toggleTab = (index) => {
    setactiveTab(prevState =>
        prevState.includes(index)
            ? prevState.filter(tab => tab !== index)
            : [...prevState, index]
    );
};

  return (
    <section class="w-full flex place-content-center px-6 bg-gray-100" id="faqs">
      <div class="container max-w-full w-full lg:w-11/12 xl:w-10/12 2xl:w-8/12 mx-auto md:px-6 pt-10 lg:pt-16 flex flex-col justify-around items-center">
      <div class="text-center pb-5 md:pt-0 md:mr-5">
         <h2 class="text-center text-gray-500 text-xl md:text-xl lg:text-2xl xl:text-3xl 2xl:text-4xl">Got Questions?</h2>
         <h2 className='text-2xl md:text-4xl lg:text-5xl xl:text-6xl 2xl:text-7xl'>Read our <span class="text-orange">FAQs.</span></h2>
      </div>
      <div class="career-faq p-2 md:p-5 w-full md:ml-5">
         <div class="px-1 py-5 toc-detail">
            <div class="container max-w-full">
               <div class="acc relative">
                {
                   FAQContent?.map((content, index) => {
                    return(
                        <div className="acc__card py-3 rounded border-gray-600 border px-5 py-8">
                            <div className={`acc__title text-base haschildren toc-heading ${activeTab.includes(index) ? 'active' : ''}`} onClick={() => toggleTab(index)}>{content.question}</div>
                            <div className={`__acc__panel__new ${activeTab.includes(index) ? 'show__acc__panel__new' : ''}`} dangerouslySetInnerHTML={{ __html: (content.answer) }} />
                        </div>
                    )
                   }) 
                }
                </div>
            </div>
         </div>
      </div>
    </div>
  </section>
  )
}

const renderFAQ=()=>{
    if(typeof window !== "undefined"){ 
        let id = document.querySelector("#faqSection")
        let content = id?.getAttribute('data-content')
        if (id && content){
            ReactDOM.render(
                <FAQ FAQContent= {JSON.parse(content)} />,
                id
            )
        }
    }
}

setTimeout(renderFAQ, 100)

export default FAQ